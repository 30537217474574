 @font-face {
    font-family: 'Alegreya';
    src: url('Alegreya.ttf') format('truetype');
}
// @font-face {
//     font-family: 'Anastasia', regular;
//     src: url('Anastasia.ttf') format('truetype');
// }

@font-face {
    font-family: 'Pinyon';
    src: url(PinyonScript-Regular.ttf) format('truetype');
}